import React from 'react';
import styled from '@emotion/styled';
// import core components
import { toUTCDateTime } from 'utils/moment';

const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${({theme}) => theme.shadow.card};
  p {
    margin: 0px;
    padding: 10px 0px;
  }
  .orderDetails__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({theme}) => theme.colors.stroke};
    padding: 0 1rem;
    .title {
      line-height: 48px;
      margin: 0px;
    }
  }
  
  .orderDetails__orders {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .orderDetails__orders li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
  }
  
  .orderDetails__subtotal {\
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({theme}) => theme.colors.lightBlue};
    padding: 0 1rem;
  }
  
  .orderDetails__total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${({theme}) => theme.colors.lightBlue};
    padding: 0 1rem;
    border-radius: 0 0 6px 6px;
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    margin-bottom: 20px;
    .title {
      font-size: 24px;
    }
  }
`;

const OrderDetails = ({ order }) => {
  return (
    <Wrapper>
        <div className="orderDetails__header">
          <h2 className="title">Order Detail</h2>
        </div>
        <ul className="orderDetails__orders">
          <li>
            <p className="orderName">Order Number</p>
            <p className="orderPrice">{order.orderId}</p>
          </li>
          <li>
            <p className="orderName">Creation Date</p>
            <p className="orderPrice">{toUTCDateTime(order.creationDate)}</p>
          </li>
        </ul>
        <div className="orderDetails__subtotal">
          <p className="subtotal">Order Status</p>
          <p className="subtotal-sum">{order.status}</p>
        </div>
        <div className="orderDetails__total">
          <p className="total bold">Order Total</p>
          <p className="total-sum bold">{`$ ${order.orderTotal}`}</p>
        </div>
    </Wrapper>
  )
};

export default OrderDetails;
