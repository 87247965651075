import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
// import core components
import { toUTCDateTime } from 'utils/moment';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
`;
const LineItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: ${props => props.theme.borderRadius.default};
  box-shadow: ${({theme}) => theme.shadow.card};
  padding: 10px;
  img,
  p {
    margin: 0px;
  }
  .deliveryDaty {
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
  .bold {
    padding-right: 10px
  }
  .main__img {
    height: 255px;
    width: auto;
    margin-right: 15px;
    box-shadow: ${props => props.theme.shadow.card};
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      display: none;
    }
  }
  .main__productDetails {
    display: flex;
    flex-direction: column;
    height: 275px;
    padding-top: 1rem;
    padding-right: 2rem;
    border-bottom: 1px solid #ebebeb;
  }
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`;
const OrderLineItem = ({ order }) => {
  return (
    <Wrapper>
      {order.lineItems?.length &&
        order.lineItems.map(lineItem => (
          <LineItem key={lineItem.id}>
            <img
              alt="thumbnail image"
              class="main__img"
              src={`${process.env.GATSBY_S3_BUCKET_BASE_URL}${lineItem.thumbnailFrontUrl}`}
            />
            <div className="content">
              <div class="restDetails">
                <div class="deliveryDaty">
                  <span class="bold">LineItem Id:&nbsp;</span>
                  <span>{lineItem.lineItemId}</span>
                </div>
                <div class="deliveryDaty">
                  <span class="bold">Unit Price:&nbsp;</span>
                  <span>{lineItem.unitPrice}</span>
                </div>
                <div class="deliveryDaty">
                  <span class="bold">Quantity:&nbsp;</span>
                  <span>{lineItem.quantity}</span>
                </div>
                <div class="deliveryDaty">
                  <span class="bold">Total Price:&nbsp;</span>
                  <span>{lineItem.totalPrice}</span>
                </div>
                <div class="deliveryDaty">
                  <span class="bold">Delivery Option:&nbsp;</span>
                  <span>{lineItem.metadata.delivery.option}</span>
                </div>
                <div class="deliveryDaty">
                  <span class="bold">Dispatch Date:&nbsp;</span>
                  <span>{toUTCDateTime(lineItem.metadata.delivery.dispatchDate)}</span>
                </div>
                <div class="deliveryDaty">
                  <span class="bold">Delivery Date:&nbsp;</span>
                  <span>{lineItem.metadata.delivery.deliveryDate}</span>
                </div>
              </div>
            </div>
          </LineItem>
        ))}
    </Wrapper>
  );
};

export default OrderLineItem;
