import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
// import core components
import { Layout, Container } from 'layouts';
import { OrderDetails, OrderLineItem } from 'components/pages/order';
import { LinkButton, ImageBanner } from 'components';
import withLocation from "components/hocs/withLocation";
import { ordersService } from 'services';
import LoadingGif from '/static/gifs/loading.gif';
import { queryHelper } from 'helpers';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block
  }
`;
const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 90px);
`;

const OrderPage = props => {
  const { data: { topBanner }, search } = props;
  const { id } = search; // fetch order-id info from the url
  const [order, setOrder] = useState([]); // Order state
  const [bLoading, setBLoading] = useState(false); // Show loading status of multi-step form
  // Load order when there is a order id
  const loadData = async id => {
    setBLoading(true);
    try {
      // fetch order
      const order = await ordersService.getOrder(id);
      setOrder(order);
    } catch (error) {
      console.log(error);
    }
    setBLoading(false);
  }
  useEffect(() => {
    id && loadData(id)
  }, [id]);

  return (
    <Layout title="Order Detail Page">
      <Container>
        {/*topBanner.nodes?.length && <ImageBanner bgFluid={queryHelper.getChildImgFluid(topBanner.nodes[0])} /> */}
        <LinkButton text="Back to Dashboard" link="/my-account" spacing={true} />
        {!bLoading ? 
          <Wrapper style={{margin: "20px"}}>
            {order[0] && <>
              <OrderDetails order={order[0]}/>
              <OrderLineItem order={order[0]} />
            </>}
          </Wrapper> 
        :<LoadingWrapper><img className="loading-gif large" src={LoadingGif} alt="image loading" /></LoadingWrapper>}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query MyOrderDetailPageQuery {
    topBanner: allNodeBanner(filter: {title: {eq: "myAccountBanner"}}) {
      nodes {
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [WEBP]
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;

export default withLocation(OrderPage);